import React from "react";
import PropTypes from "prop-types";
// import "./CollectionItemCard.scss";

const aliasTypeGenerator = (type) => {
  console.log({ type, info: "type" });
  let aliasAction = "View";
  let aliasType = "Now";

  switch (type) {
    case "pdf":
      aliasType = "PDF";
      break;
    case "doc":
    case "docx":
    case "txt":
      aliasAction = "Read";
      aliasType = "Now";
      break;
    case "ppt":
    case "pptx":
      aliasType = "Presentation";
      break;
    case "mp4":
    case "avi":
    case "mov":
    case "mpeg":
      aliasType = "Video";
      break;
    case "mp3":
    case "m4a":
    case "flac":
    case "wav":
    case "wma":
    case "aac":
      aliasAction = "Listen";
      aliasType = "Now";
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
    case "image":
      aliasType = "Image";
      break;
    case "html":
      aliasType = "Page";
      break;
    case "csv":
      aliasType = "CSV File";
      break;
    default:
      aliasType = "Now";
      break;
  }

  return `${aliasAction} ${aliasType}`;
};

const CardThumbnail = ({item}) => {
  const fileExtension = item.ext || "file";

  // handle the PDF case
  if (fileExtension === "pdf") {
    return (
      <div className="bg-gray-100 rounded-lg p-2" id="df_manual_book" tags="3d,images" onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center">
          <img className="mx-auto object-contain h-32 w-64 flex-shrink-0 rounded-md" src={item.thumbnailUrl} alt="" />
        </div>
      </div>
    )
  }
  // handle an item with a thumbnail
  else if (item.thumbnailUrl) {
    return (
      <img className="mx-auto object-cover h-32 w-64 flex-shrink-0 rounded-md" src={item.thumbnailUrl} alt="" />
    )
  }
  // default to a file icon
  return (
    <div className="file-thumbnail-container"> 
      <div className="mx-auto w-32 h-32 bg-gray-200 flex items-center justify-center text-xl font-bold text-gray-500 rounded-lg">
        {fileExtension.toUpperCase()}
      </div>
    </div>
  );
};


// thumbnailUrl,
// title: item.meta.contentTitle || props.data.name,
// description: item.meta.contentDescription || '',
// ext: item.original.ext,
// type: item.type,
// shortUrl: item.urls.short

const CollectionItemCard = (props) => {
  const [expandDesc, setExpandDesc] = React.useState(false);

  // const renderThumbnailImage = (item) => {
  //   if (item.ext === "pdf") {
  //     return (
  //       <div
  //         className="_df_thumb"
  //         id="df_manual_book"
  //         tags="3d,images"
  //         source={item.fullUrl}
  //         thumb={item.thumbnailUrl}
  //         onClick={(e) => e.stopPropagation()}
  //       >
  //         <div className="_df_book-cover">
  //           <img
  //             className="_df_thumbnail_image"
  //             src={item.thumbnailUrl}
  //             alt="pdf thumbnail"
  //           />
  //           <span className="_df_book-title">{props.data.title}</span>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     let thumbContent;
  //     if (item.thumbnailUrl) {
  //       thumbContent = (
  //         <img
  //           className="thumbnail-image"
  //           src={item.thumbnailUrl}
  //           alt="other thumbnail"
  //         />
  //       );
  //     } else {
  //       thumbContent = (
  //         <div className="thumbnail-image no-thumbnail">
  //           {(item.ext || "").toUpperCase()}
  //         </div>
  //       );
  //     }
  //     // the non PDF thumbnail
  //     return (
  //       <div className="thumbnail-section">
  //         {thumbContent}
  //       </div>
  //     );
  //   }
  // };


  const buildCardUrl = (url) => {
    const targetUrl = new URL(props.data.shortUrl);
    if (props.sidQuery) {
      targetUrl.searchParams.set("sid", props.sidQuery);
    }
    if (props.siteId) {
      targetUrl.searchParams.set("siteId", props.siteId);
    }
    return targetUrl.toString();
  };

  /**
   * Open the item view in a new tab using the shortUrl and attribute 
   * the view back to the shareId and siteId if applicable
   */
  const onCardClick = () => {
    const targetUrl = buildCardUrl();

    window.open(targetUrl.toString(), "_blank");
  };

  const onLinkClick = (e) => {
    e.stopPropagation();
    localStorage.setItem(
      "params",
      JSON.stringify({
        fullUrl: props.data.fullUrl,
        branding: props.branding,
      })
    );
  };

  return (
    <li
      key={props.data.id}
      className="card-action col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow group"
      onClick={onCardClick}
    >
      <div className="flex flex-1 flex-col p-8 relative">
        <CardThumbnail item={props.data} />
        <h3 className="card-title mt-6 text-sm font-medium text-gray-900">
          {props.data.title}
        </h3>
        {/* Always on description / subtitle */}
        {/* <dl className="card-description mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only"></dt>
          <dd className="text-sm text-gray-500">{subtitle}</dd>
        </dl> */}
        
        {/* Detail Description on hover */}
        { props.data.description && props.data.description.length > 0 && (
        <div className="more-info opacity-0 absolute inset-0 p-4 bg-white bg-opacity-90 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
          <p className="text-sm text-gray-600">
            {props.data.description}
          </p>
        </div>
        )}
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <a
              href={buildCardUrl()}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-slate-700"
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {aliasTypeGenerator(props.data.ext).toUpperCase()}
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

CollectionItemCard.propTypes = {
  data: PropTypes.object,
};

export default CollectionItemCard;
