import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import CollectionItemCard from "./CollectionItemCard";
// import "./Home.scss";

const DEFAULT_BRANDING_COLOR = "#47c1cc";


const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className="loading-wrapper fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-100 bg-gray-100 z-50">
        <BeatLoader size={20} margin={4} color="#36D7B7" />
      </div>
    );
  }
  return null;
};


/**
 * Build out the grid of cards for the collection items
 * Cards have a thumbnail or placeholder thumb
 * @param {Array} collectionItems 
 * @returns 
 */
const CardsGrid = (collectionItems, sidQuery, userId) => {
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      {collectionItems.map((it, idx) => (
        <CollectionItemCard
          data={it}
          branding={collectionItems.branding}
          key={`card_${idx}`}
          sidQuery={sidQuery}
          siteId={collectionItems.id}
          ownerBy={userId}
          collectionId={collectionItems.collectionId}
        />
      ))}
    </ul>
  );
}


const Home = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [enablePersonalization, setEnablePersonalization] = React.useState(true);
  const [collection, setCollection] = React.useState({
    collection: { items: [] },
  });
  const [sidQuery, setSidQuery] = React.useState(null);
  const [ctaData, setCtaData] = React.useState(null);
  const [userId, setUserId] = React.useState("");

  const history = useHistory();

  const initConfigurations = async () => {
    const metaTags = document.getElementsByTagName("meta");
    let metaUserId = null;
    let metaCollectionId = null;
    let metaTenantId = null;

    for (let i = 0; i < metaTags.length; i += 1) {
      if (metaTags[i].name === "userId") {
        metaUserId = metaTags[i].content;
      }

      if (metaTags[i].name === "collectionId") {
        metaCollectionId = metaTags[i].content;
      }

      if (metaTags[i].name === "tenantId") {
        metaTenantId = metaTags[i].content;
      }

      // if (metaTags[i].name === 'ctaId') {
      //   ctaId = metaTags[i].content;
      // }
    }

    let searchText = history.location.search;

    searchText = searchText.replace("?", "");
    const searchParams = searchText.split("&");
    let newSidQuery;
    const searchValues = {};

    if (searchParams.length > 0) {
      searchParams.forEach((sp) => {
        const valuePair = sp.split("=");
        searchValues[valuePair[0]] = valuePair[1];
      });
    }

    if (searchValues.sid) {
      newSidQuery = searchValues.sid;
    }

    try {
      if (
        !metaUserId ||
        !metaCollectionId ||
        metaUserId === "" ||
        metaCollectionId === ""
      ) {
        gotoNotFound();
        return;
      }

      setUserId(metaUserId);
      const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?ownedBy=${metaUserId}&collectionId=${metaCollectionId}`;
      const configOptions = {
        headers: {
          "Content-Type": "application/json",
        },
        crossdomain: true,
      };

      const response = await axios.get(collectionAPIUrl, configOptions);

      if (response.data.data.deletedAt) {
        gotoRemovedSite();
        return;
      }

      process.env.OG_TITLE = response.data.data.title;
      // turn off avatar and contact info if flagged off for this site
      const { enablePersonalization: newEnablePersonalization } = response.data.data;
      if (newEnablePersonalization === false) {
        setEnablePersonalization(false);
      }
      console.log({data: response.data.data});
      setCollection(response.data.data);
      setIsLoading(false);
      setSidQuery(newSidQuery);

      if (newSidQuery) {
        try {
          const shareAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/users/v1/shares/${newSidQuery}?tenantId=${metaTenantId}`;
          const shareResponse = await axios.get(shareAPIUrl, configOptions);
          const shareInfo = shareResponse.data.data;

          if (shareInfo.ctaId) {
            const ctaResponse = await axios.get(
              `${process.env.REACT_APP_API_SERVER_BASE_URL}/ctas/v1/ctas/${shareInfo.ctaId}/public?tenantId=${metaTenantId}`
            );

            setCtaData({
              buttonStyle: ctaResponse.data.data.options.style,
              message: ctaResponse.data.data.message,
              buttonText: ctaResponse.data.data.buttonText,
              targetUrl: ctaResponse.data.data.targetUrl,
              userName: ctaResponse.data.data.userName,
              imageUrl: ctaResponse.data.data.imageUrl,
            });
          }
        } catch (errr) {
          console.log("Fetch share info error: ");
          console.log(errr);
        }
      }
    } catch (error) {
      console.log("Home error: ", error.message);
      gotoNotFound();
    }
  };

  React.useEffect(() => {
    initConfigurations();
  }, []);


  const gotoNotFound = () => {
    if (history.location.pathname.includes('/sites/p/')) {
      return
    }

    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  let user = {
    avatar: {},
  };

  if (collection.user) {
    user = collection.user;
  }

  let logoImageUrl = null;
  let color = DEFAULT_BRANDING_COLOR;
  if (collection.branding) {
    color = collection.branding.color || DEFAULT_BRANDING_COLOR;

    if (collection.branding.logo) {
      logoImageUrl = collection.branding.logo;
    }
  }

  // flags
  const enableDescription =
    collection.description && collection.description.trim().length > 0;
  const enableTitleBox = enablePersonalization || enableDescription; // turn off the title box if both are off

  if (ctaData) {
    console.log(ctaData.imageUrl);
  }

  // //////////////////////////////////////////////////////////////////////////////////////////
  // Rendering

  // Utility to darken color slightly for hover effect
  const darkenColor = (hexColor, percent) => {
    // Convert hex color to RGB
    const num = parseInt(hexColor.slice(1), 16);
    const r = (num >> 16) & 255;
    const g = (num >> 8) & 255;
    const b = num & 255;
  
    // Calculate darkened color by decreasing each channel by a percentage
    const decrease = (channel) => Math.floor(channel * (1 - percent / 100));
    const newR = decrease(r);
    const newG = decrease(g);
    const newB = decrease(b);
  
    // Convert RGB back to hex and return
    return `#${((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1)}`;
  };

  const triangle1Style = {
    backgroundColor: color,
    opacity: 0.8,
  };
  
  const triangle2Style = {
    backgroundColor: color,
    opacity: 0.5,
  };
  
  const triangle3Style = {
    backgroundColor: color, // Or you can adjust this if you want a lighter shade
    opacity: 0.4,
  };

  const hoverColor = darkenColor(color, 10); // Darken color by 10%

  
  return (
    <>
      <Helmet>
        <title>{collection.title || ""}</title>
        {/* <meta
          name="description"
          content={collection.description}
        />
        <meta
          property="og:title"
          content={collection.title || ""}
        />
        <meta
          property="og:description"
          content={collection.description}
        />
        <meta property="og:image" content={logoImageUrl} />
        <meta property="siteId" content={collection.id} /> */}
      </Helmet>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <>
          <div className="h-[500px] flex justify-center items-center bg-white text-white text-[36px] font-semibold absolute w-full overflow-hidden transform origin-left">
            <div
              className="custom-background absolute w-full h-[120px] top-[-60px] skew-y-[-16deg] overflow-hidden origin-left opacity-90"
              style={{ backgroundColor: color }}
            ></div>
            <div
              className="triangle1 flex justify-center items-center text-white text-[36px] font-semibold absolute w-full h-full skew-y-[-8deg] overflow-hidden origin-left"
              style={triangle1Style}
            ></div>
            <div
              className="triangle2 absolute h-[80px] right-[120px] bottom-0 left-0 skew-y-[-8deg] overflow-hidden origin-left"
              style={triangle2Style}
            ></div>
            <div
              className="triangle3 absolute h-[100px] right-[40px] bottom-[calc(60%*-0.06)] left-2/4 skew-y-[-8deg] overflow-hidden origin-left"
              style={triangle3Style}
            ></div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
            <div className="flex-grow mb-4 rounded-md">
              {/* Content goes here */}

              {/* Logo */}
              <div className="flex justify-end pt-2 mb-4">
                <img
                  className="h-auto max-h-24 w-auto"
                  src="https://s3.us-west-2.amazonaws.com/dev-uploads-contentcamel-io/DEVca675b28c1f047e1891df9e102572401%2Fbranding%2F0e27daec-7d63-42bc-a05a-3512186f4b3b%2Flogo-zendesk-support.png"
                  alt="Company Logo"
                />
              </div>
              {/* Header */}
              {enableTitleBox && (
                <div className="flex-grow bg-white py-4 px-4 rounded-md drop-shadow-md">
                  <div className="md:flex md:items-center md:justify-between md:space-x-5">
                    <div className="flex items-start space-x-5">
                      <div className="flex-shrink-0">
                        {/* Remove avatar image if one isn't set -- placeholder may not make sense here */}
                        {enablePersonalization && user.avatar.thumbnail ? (
                          <div className="relative">
                            <img className="h-20 w-20 rounded-full border-4 border-white shadow-lg" src={user.avatar.thumbnail} alt={user.name || ""} />
                            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                          </div>
                          ) : null}
                      </div>
                      {/*
                      Use vertical padding to simulate center alignment when both lines of text are one line,
                      but preserve the same layout if the text wraps without making the image jump around.
                  */}
                      <div className="pt-1.5 z-10">
                        {/* TODO add in title when editable in main UI */}
                        {/* <h1 className="text-2xl font-bold text-gray-900">
                    {collection.title}
                    </h1> */}
                        <p className="text-md font-medium text-gray-500 mt-1">{collection.description}</p>
                      </div>
                    </div>
                    {enablePersonalization && (
                      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                        <a
                          href={`mailto:${user.email}`}
                          target="_top"
                          className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                          style={{ backgroundColor: color, transition: "background-color 0.2s ease-in-out" }}
                          onMouseEnter={(e) => (e.target.style.backgroundColor = hoverColor)}
                          onMouseLeave={(e) => (e.target.style.backgroundColor = color)}
                        >
                          Contact
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Main Content */}
            <main className="flex-grow bg-gray-100 py-8 px-8 rounded-md drop-shadow-md">
              {CardsGrid(collection.items, sidQuery, userId)}
            </main>

            {/* Footer */}
            <footer className="bg-transparent py-4">
              <div className="container mx-auto">
                <div className="text-right text-gray-400 italic text-sm">
                  <a href="https://www.contentcamel.io/">Powered by Content Camel</a>
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </>
  );
};

Home.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default Home;
